// DownloadButtons.js
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

function DownloadButtons() {
  const buttonStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%', // 使按钮宽度自适应
    maxWidth: '250px', // 调整按钮最大宽度
    height: '80px',
    backgroundColor: '#06141B',
    color: '#CCD0CF',
    border: '2px solid #CCD0CF',
    borderRadius: '5px',
    padding: '20px',
    cursor: 'pointer',
    transition: 'background-color 0.3s, color 0.3s',
    textDecoration: 'none',
    marginBottom: '20px' // 调整底部间距
  };

  const imgStyle = {
    marginRight: '10px',
    width: '50px',
    height: 'auto'
  };

  const textStyle = {
    fontSize: '18px',
  };

  const payIconStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap', // 使图标在小屏幕上换行
    margin: '20px 0'
  };

  const individualIconStyle = (size) => ({
    width: size,
    height: 'auto',
    margin: '2px' // 给每个图标添加左右间隔
  });

  return (
    <Container fluid style={{ backgroundColor: '#253745', padding: '60px 0', textAlign: 'center' }}>
      <Row className="justify-content-center g-1" style={{ gap: '10px' }}>
        <Col xs={12} sm={4} md={3} className="d-flex justify-content-center">
          <button style={buttonStyle}>
            <img src={`${process.env.PUBLIC_URL}/IOS-icon.png`} alt="Download for iOS" style={imgStyle} />
            <div style={textStyle}>Download for iOS</div>
          </button>
        </Col>
        <Col xs={12} sm={4} md={3} className="d-flex justify-content-center">
          <button style={buttonStyle}>
            <img src={`${process.env.PUBLIC_URL}/Android-icon.png`} alt="Download for Android" style={imgStyle} />
            <div style={textStyle}>Download for Android</div>
          </button>
        </Col>
        <Col xs={12} sm={4} md={3} className="d-flex justify-content-center">
          <button style={buttonStyle}>
            <img src={`${process.env.PUBLIC_URL}/PlayNow-icon.png`} alt="Play Now" style={{ ...imgStyle, marginRight: '20px' }} />
            <div style={textStyle}>Play Now</div>
          </button>
        </Col>
      </Row>
      <Row>
        <Col>
          <div style={payIconStyle}>
            <img src={`${process.env.PUBLIC_URL}/pay-icon/transfer.png`} alt="Bank Transfer" style={individualIconStyle('28px')} />
            <img src={`${process.env.PUBLIC_URL}/pay-icon/VISA.png`} alt="Visa" style={individualIconStyle('50px')} />
            <img src={`${process.env.PUBLIC_URL}/pay-icon/mastercard-icon.png`} alt="MasterCard" style={individualIconStyle('40px')} />
            <img src={`${process.env.PUBLIC_URL}/pay-icon/Paypal.png`} alt="PayPal" style={individualIconStyle('80px')} />
            <img src={`${process.env.PUBLIC_URL}/pay-icon/USTD-icon.png`} alt="USDT" style={individualIconStyle('32px')} />
            <img src={`${process.env.PUBLIC_URL}/pay-icon/BTC.png`} alt="Bitcoin" style={individualIconStyle('30px')} />
            <img src={`${process.env.PUBLIC_URL}/pay-icon/alipay.png`} alt="Alipay" style={individualIconStyle('28px')} />
            <img src={`${process.env.PUBLIC_URL}/pay-icon/WechatPay.png`} alt="WeChat Pay" style={individualIconStyle('25px')} />
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default DownloadButtons;
