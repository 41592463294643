// Header.js

import React, { useState } from 'react';
import { Navbar, Nav, Container, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import PrivacyModal from './PrivacyModal';

function Header() {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);

  const buttonStyle = {
    backgroundColor: '#06141B',
    color: '#85de65',
    border: '1px solid #85de65',
    borderRadius: '6px',
    padding: '8px 16px',
    fontSize: '18px',
    cursor: 'pointer',
    transition: 'background-color 0.3s, color 0.3s',
    boxShadow: 'none',
    marginRight: '12px',
  };

  const downloadButtonStyle = {
    backgroundColor: '#06141B',
    color: '#FFC107',
    border: '1px solid #FFC107',
    borderRadius: '6px',
    padding: '8px 16px',
    fontSize: '18px',
    cursor: 'pointer',
    transition: 'background-color 0.3s, color 0.3s',
    boxShadow: 'none',
    marginRight: '12px',
  };

  const noCaretStyle = {
    '&::after': { display: 'none' }
  };

  return (
    <header style={{ backgroundColor: '#06141B', color: '#CCD0CF', padding: '10px 10px 0', marginTop: '15px' }}>
      <Container fluid>
        <Navbar expand="md">
          <Navbar.Brand as={Link} to="/" className="d-flex align-items-center">
            <img src={`${process.env.PUBLIC_URL}/logo-icon.png`} alt="Logo" style={{ height: '50px', marginRight: '10px' }} />
            <img src={`${process.env.PUBLIC_URL}/appname-icon.png`} alt="App Name" style={{ height: '40px', marginRight: '10px' }} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" style={{ borderColor: 'transparent', padding: 0 }}>
            <img src={`${process.env.PUBLIC_URL}/menu-icon.png`} alt="Menu" style={{ height: '30px' }} />
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <Nav.Link href="#championship" style={buttonStyle}>Championship</Nav.Link>
              <Nav.Link href="#events" style={buttonStyle}>Events</Nav.Link>
              <Nav.Link onClick={() => setShowPrivacyModal(true)} style={buttonStyle}>Privacy</Nav.Link>
              <Nav.Link as={Link} to="/support" style={buttonStyle}>Support</Nav.Link>
              <Dropdown show={showDropdown} onMouseEnter={() => setShowDropdown(true)} onMouseLeave={() => setShowDropdown(false)}>
                <Dropdown.Toggle style={{ ...buttonStyle, ...noCaretStyle }} id="dropdown-basic">
                  Contact Us
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ backgroundColor: '#06141B', color: '#85de65' }}>
                  <Dropdown.Item href="#/action-1" style={{ color: '#85de65' }}>Email: tradematch@gmx.com</Dropdown.Item>
                  <Dropdown.Item href="#/action-2" style={{ color: '#85de65' }}>Telegram: @tmclient</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Nav.Link href="#download" style={downloadButtonStyle}>Download</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Container>
      <style>{`
        @media (max-width: 768px) {
          .navbar-collapse {
            background-color: #06141B;
          }
          .nav-link,
          .dropdown-toggle {
            width: 100%;
            text-align: center;
            margin-bottom: 10px;
          }
        }
      `}</style>
      <PrivacyModal show={showPrivacyModal} handleClose={() => setShowPrivacyModal(false)} />
    </header>
  );
}

export default Header;